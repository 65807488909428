import { createContext, useState } from "react";

const ClockTimeContext = createContext({});

export const ClockTimeProvider = ({ children }) => {
  const [clockTime, setClockTime] = useState({
    regularClock: new Date(), // not stored in DB
    /**
     *
     */
    localStorageKey: null, // local storage key where the data will be stored
    /**
     *
     */
    date: new Date(), // current date
    shiftMinutes: 0, // target runtime
    /**
     *
     */
    startTime: null, // work clock start stamp

    elapsedTime: null, // for showing forward timer [total time]
    elapsedMilliseconds: 0, // forward timer milliseconds [total time]

    projectedFinishTime: null, // [lastToggleTime]

    workTime: null, // for showing forward timer [work time]
    workTimeMilliseconds: 0, // forward timer milliseconds [work time]
    workRemainingTime: null, // for showing backward timer [remaining work time]
    workTimeCountdownMilliseconds: 0, // backward timer milliseconds [remaining work time]
    /**
     *
     */
    lastToggleTime: null, // last clock toggle stamp
    toggleRecords: [],
    /**
     *
     */
    breakOn: false, // [workTime, breakTime, projectedFinishTime]
    breakTime: null, // for showing forward timer [break time]
    breakTimeMilliseconds: 0, // forward timer milliseconds [break time]
    lastBreakTimeMilliseconds: 0, // for storing last break time forward timer milliseconds
    finishTime: null, // work clock finish stamp
    breaks: [], // record of clock toggle stamps
    /**
     *
     */
    overTimeOn: false, //overtime indicator
    overTimeMilliseconds: 0, // forward timer milliseconds [over time]
    overTime: null, // for showing forward timer [over time]
  });

  return (
    <ClockTimeContext.Provider value={{ clockTime, setClockTime }}>
      {children}
    </ClockTimeContext.Provider>
  );
};

export default ClockTimeContext;
