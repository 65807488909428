import CryptoJS from "crypto-js";

const SECRET_KEY = "TEST";
export const encryptData = (keyName, data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  localStorage.setItem(keyName, encrypted);
};

export const decryptData = (keyName) => {
  const encrypted = localStorage.getItem(keyName);
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  }
  return null;
};
