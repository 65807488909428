export const months = Array.from({ length: (12 - 1) / 1 + 1 }, (_, i) => {
  const monthNumber = 1 + i * 1;
  const monthName = new Date(2022, monthNumber - 1, 1).toLocaleString(
    "default",
    { month: "long" }
  );
  return { number: monthNumber, name: monthName };
});

export function convertToArrays(arr) {
  const result = [];

  for (let i = 0; i < arr.length; i += 2) {
    if (i + 1 < arr.length) {
      result.push([arr[i], arr[i + 1]]);
    } else {
      result.push([arr[i]]);
    }
  }

  return result;
}

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
};
